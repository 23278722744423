<template>
  <div class="app">
    <!-- header-fixed为页面下滑后导航样式 -->
    <div id="yaowei" class="app">
      <!-- 网站顶部 -->
      <OfficialOtherHead :homePageSetting="homePageSetting" />
      <!-- business -->
      <div class="main">
        <!-- 标题 -->
        <div class="content-title">
          <img class="hidden-xs" :src="bannerImgUrl" />
          <img class="hidden-mx" :src="bannerImgUrl" />
          <div class="content-box">
            <h1>{{ title }}</h1>
            <div v-for="(item, index) in briefList" :key="index">
              {{ item }}
            </div>
          </div>
        </div>
        <!-- 内容 -->
        <div class="panel">
          <!-- 本页导航 -->
          <div class="navbar">
            <a
              href="#"
              @click.prevent="changeClassify(item.id)"
              :class="[
                'navbar-item',
                item.id === classifyId ? 'navbar-item-active' : '',
              ]"
              v-for="item in allList"
              :key="item.id"
              >{{ item.classifyName }}</a
            >
          </div>
          <div class="list">
            <!-- item-active 活动进行中样式 -->
            <div v-for="item in dataList" :key="item.id" class="item">
              <div class="item-img">
                <div class="item-img-box"><img :src="item.imgUrl" /></div>
              </div>
              <div class="item-text">
                <div class="it-box">
                  <h3 class="item-title">{{ item.serviceName }}</h3>
                  <div
                    class="item-tip"
                    v-for="(brief, index) in item.briefList"
                    :key="index"
                  >
                    {{ brief }}
                  </div>
                  <div class="item-more">
                    <a :href="'/companyList?id=' + item.id" target="_blank"
                      ><span>查看更多</span></a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="listBox">
            <div v-for="item in dataList" :key="item.id" class="listBox-item">
              <router-link to="#">
                <span class="listBox-item-img"><img :src="item.imgUrl" /></span>
                <h3 class="listBox-item-title">{{ item.serviceName }}</h3>
                <div
                  class="listBox-item-tip"
                  v-for="(brief, index) in item.briefList"
                  :key="index"
                >
                  {{ brief }}
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- end business -->
      <!-- 网站底部 -->
      <OfficialFoot :homePageSetting="homePageSetting" />
    </div>
  </div>
</template>

<script>
import OfficialOtherHead from "./OfficialOtherHead";
import OfficialFoot from "./OfficialFoot";
import { getHomePageSetting, setSeo } from "../api/defaultDataModel";
export default {
  name: "business",
  components: {
    OfficialOtherHead,
    OfficialFoot,
  },
  data: function () {
    return {
      homePageSetting: getHomePageSetting(),
      classifyId: undefined,
      bannerImgUrl: undefined,
      title: undefined,
      briefList: [],
      allList: [],
      dataList: [],
    };
  },
  methods: {
    onSubmit() {
    },
    changeClassify(id) {
      if (!id) {
        return false;
      }
      //变更栏目
      const list = this.allList.filter((item) => item.id === id);
      if (list && list.length > 0) {
        this.dataList = list[0].children;
        this.classifyId = id;
        this.bannerImgUrl = list[0].bannerImgUrl;
      }
      return false;
    },
  },
  created() {
    //首页数据
    this.$http
      .get(this.TzConfig.BASE_URL + "/h5/businessScope/list")
      .then((res) => {
        if (8000 === res.data.code) {
          this.allList = res.data.data;
          if (this.$route.query.id && /^\d{15,}$/.test(this.$route.query.id)) {
            if (
              this.allList.filter((item) => item.id === this.$route.query.id)
                .length > 0
            ) {
              this.classifyId = this.$route.query.id;
            }
          }
          if (!this.classifyId && this.allList.length > 0) {
            this.classifyId = this.allList[0].id;
          }
          this.changeClassify(this.classifyId);
        }
      })
      .catch((err) => {
      });

    //首页数据
    this.$http
      .get(this.TzConfig.BASE_URL + "/h5/homePageSetting/getSetting")
      .then((res) => {
        if (8000 === res.data.code) {
          this.homePageSetting = res.data.data;
          setSeo(
            this.homePageSetting.seoTitle,
            this.homePageSetting.seoDescription,
            this.homePageSetting.seoKeyword
          );
        }
      })
      .catch((err) => {
      });
  },
};
</script>
<style scoped>
.main {
  margin: 88px auto 0;
  width: 100%;
}
.content-title {
  max-width: 1920px;
  position: relative;
  height: 360px;
  overflow: hidden;
}
.content-title img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hidden-xs {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hidden-mx {
  display: none;
}
.content-box {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  display: -webkit-flex;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  padding: 0 68px;
}
.content-box h1,
.content-box div {
  text-align: center;
  color: #fff;
}
.content-box h1 {
  font-size: 40px;
  line-height: 52px;
}
.content-box div {
  margin: 13px 0 0;
  font-size: 16px;
  line-height: 22px;
}
.panel {
  position: relative;
  z-index: 999;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}
.navbar {
  display: -webkit-flex;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 38px 0 40px;
  width: 100%;
}
.navbar .navbar-item {
  position: relative;
  display: inline-block;
  margin: 0 30px;
  padding: 0 0 9px;
  vertical-align: top;
  font-size: 18px;
  line-height: 24px;
}
.navbar .navbar-item:after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  margin: 0 auto;
  content: "";
  width: 100%;
  height: 3px;
  background-color: #fff;
}
.navbar .navbar-item:hover:after,
.navbar .navbar-item-active:after {
  background: #1f5ed1;
}

.item {
  display: -webkit-flex;
  display: flex;
  flex-flow: row-reverse nowrap;
  justify-content: flex-start;
  margin: 40px 0 120px;
  width: 100%;
}
.item:nth-child(1) {
  margin: 0 0 120px;
}
.item-img {
  position: relative;
  width: 43%;
  max-height: 400px;
}
.item-img-box {
  width: 100%;
  max-height: 400px;
  overflow: hidden;
}
.item-img:before {
  position: absolute;
  left: -14%;
  right: 0;
  bottom: -24%;
  z-index: 0;
  content: "";
  height: 113%;
  background-color: #f4f6f8;
}

.item:nth-child(2n-1) {
  flex-flow: row nowrap;
}
.item:nth-child(2n-1) .item-img::before {
  position: absolute;
  left: 0;
  right: -14%;
}
.item-img img {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  min-height: 360px;
  object-fit: cover;
}
.item-text {
  position: relative;
  width: 43.75%;
}
.it-box {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  padding: 0 36px 0 0;
  width: 100%;
}
.item:nth-child(2n-1) .it-box {
  left: 36px;
  padding: 0;
}
.item-title {
  position: relative;
  margin: 38px 0 0;
  padding: 0 0 26px;
  width: 100%;
  font-size: 48px;
  font-weight: 700;
  line-height: 64px;
}
.item-title:after {
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  content: "";
  width: 56px;
  height: 1px;
  background-color: #212121;
}
.item-tip {
  margin: 50px 0 0;
  width: 100%;
  font-size: 15px;
  line-height: 24px;
  text-overflow: ellipsis;
  display: -webkit-box;
  display: box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}
.item-more {
  margin: 30px 0 0;
}
.item-more a {
  position: relative;
  padding: 0 38px 0 0;
  font-size: 18px;
  line-height: 36px;
}
.item-more a:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  display: inline-block;
  content: "";
  width: 24px;
  height: 24px;
  background: #f9d688 url(../assets/images/arr-right-y.png) no-repeat
    center/14px;
  border-radius: 50%;
}

.listBox {
  display: -webkit-flex;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1400px;
}
.listBox .listBox-item {
  position: relative;
  margin: 0 2.28% 32px 0;
  width: calc((100% - 2.28% * 2) / 3);
  background-color: #fff;
  box-shadow: 0 10px 20px rgba(175, 174, 187, 0.16);
}
.listBox .listBox-item:nth-child(3n) {
  margin: 0 0 32px 0;
}
.listBox .listBox-item .listBox-item-img {
  display: block;
  width: 100%;
  height: 300px;
}
.listBox .listBox-item .listBox-item-img img {
  margin: 0 auto;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.listBox .listBox-item .listBox-item-title {
  padding: 18px 18px 0;
  width: 100%;
  height: calc(28px * 2 + 18px);
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  /*！ autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
}
.listBox .listBox-item .listBox-item-time {
  padding: 18px 18px 26px;
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  color: rgb(29 29 31 / 50%);
}

.footer {
  margin: 0 auto;
}

@media (max-width: 1400px) {
  .container {
    max-width: auto;
    width: 90%;
  }
}

@media (max-width: 990px) {
  .main {
    margin: 64px auto 0;
  }
  .hidden-xs {
    display: none;
  }
  .hidden-mx {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .panel {
    padding: 0 0 40px;
  }
}

@media (max-width: 768px) {
  .item-title {
    margin: 10px 0 0;
    padding: 0 0 16px;
  }
  .item-tip {
    margin: 20px 0 0;
  }
  .item-img img {
    min-height: 240px;
  }
}
@media (min-width: 641px) {
  .listBox {
    display: none;
  }
}
@media (max-width: 640px) {
  .main {
    margin: 1.28rem auto 0;
  }
  .list {
    display: none;
  }
  .content-title {
    display: none;
    visibility: hidden;
  }
  .navbar {
    padding: 0;
    margin: 0 0.48rem;
    width: calc(100% - 0.48rem * 2);
  }
  .navbar .navbar-item,
  .navbar .navbar-item-active:after {
    display: none;
  }
  .navbar .navbar-item-active {
    display: block;
    margin: 0;
    padding: 0.4rem 0;
    width: 100%;
    height: auto;
    font-size: 0.64rem;
    line-height: 0.96rem;
    color: #212121;
    text-align: left;
  }
  .panel {
    padding: 0;
  }
  .listBox {
    padding: 0 0.48rem;
    width: 100%;
  }
  .listBox .listBox-item,
  .listBox .listBox-item:nth-child(3n),
  .listBox .listBox-item:nth-child(2n) {
    margin: 0 0 0.48rem;
    padding: 0 0 0.46rem;
    width: 100%;
    box-shadow: 0 0.2rem 0.4rem rgba(175, 174, 187, 0.16);
  }
  .listBox .listBox-item .listBox-item-img {
    height: 3.6rem;
  }
  .listBox .listBox-item .listBox-item-title {
    padding: 0.34rem 0.26rem 0;
    height: auto;
    font-size: 0.36rem;
    line-height: 0.5rem;
  }
  .listBox .listBox-item .listBox-item-tip {
    padding: 0.2rem 0.26rem 0;
    font-size: 0.28rem;
    line-height: 0.5rem;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    /*！ autoprefixer: off */
    -webkit-box-orient: vertical;
    /* autoprefixer: on */
  }
  .listBox .listBox-item .listBox-item-time {
    padding: 0.26rem 0.26rem 0.46rem;
    width: 100%;
    font-size: 0.26rem;
    line-height: 0.36rem;
    color: rgb(29 29 31 / 50%);
  }
}
</style>
